export const UI_PAGE_FULL_FRAGMENT = `
  fragment uiPageFullFragment on UiPage {
        id
        uid
        name
        code
        published
        paramName
        defaultTab
        entityType {
            uid
            code
            name
        }
        active
        guest
        production
        meta
        version
        payload
        path
        footer
        header
        toolbar
        showCta
        showGdpr
        chatPanel
        existenceGraphql
        permissionGraphql
        cssClasses
        metaTitle
        metaDescription
        parentPage {
            uid
            id
        }
        childPages(orderBy: createdTime_asc) {
            id
            uid
            name
            code
            published
            active
            guest
            production
            meta
            version
            payload
            path
            footer
            header
            metaTitle
            metaDescription
            defaultTab
            existenceGraphql
            permissionGraphql
            cssClasses
            createdTime
            parentPage {
                uid
                id
                paramName
                entityType {
                    uid
                    code
                    name
                }
            }
        }
  }
`;
